import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Slider from 'components/Slider'

import s from './styles.module.css'

const SliderImages = ({ images, className, imgClassName }) => {
  if (!images || images?.length < 1) return null

  const settings = {
    dots: false,
  }

  return (
    <Slider className={classNames(s.container, className)} {...settings}>
      {images.map((image, i) => {
        const img = image?.image
        if (!img) return null
        return (
          <GatsbyImage
            key={`${img.src}${i}`}
            className={classNames(s.img, imgClassName)}
            image={getImage(img.localFile)}
            backgroundColor="#eee"
            alt="promo"
          />
        )
      })}
    </Slider>
  )
}

SliderImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        localfile: PropTypes.shape({
          childImageSharp: PropTypes.object,
        }),
      }),
    })
  ),
  className: PropTypes.string,
  imgClassName: PropTypes.string,
}
SliderImages.defaultProps = {
  images: [],
  className: '',
  imgClassName: '',
}

export default SliderImages
