import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SliderImages from 'components/SliderImages'
import LinkWordPress from 'components/LinkWordPress'

import s from './styles.module.css'

const TextImage = ({ data, style, noPageLink, className }) => {
  if (data?.length < 1) return null
  return (
    <div
      className={classNames(s.container, className, {
        [s.styleB]: style === 'b',
      })}
    >
      {data.map(
        (
          {
            title,
            description,
            images,
            link,
            linkType,
            productLinkType,
            collectionHandle,
            stockistTitle,
            productHandle,
            linkLabel,
          },
          i
        ) => (
          <div key={`${title}${i}`} className={s.item}>
            <SliderImages images={images} className={s.images} />
            <div className={s.details}>
              {title && (
                <h2
                  className={s.title}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
              {description && (
                <div
                  className={s.description}
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              )}
              <LinkWordPress
                className={s.link}
                linkType={linkType}
                link={link}
                productLinkType={productLinkType}
                collectionHandle={collectionHandle}
                productHandle={productHandle}
                stockistTitle={stockistTitle}
                linkLabel={linkLabel}
                noPageLink={noPageLink}
                fullyClickable
              />
            </div>
          </div>
        )
      )}
    </div>
  )
}

TextImage.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      linkType: PropTypes.string,
      link: PropTypes.shape({
        target: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      productLinkType: PropTypes.string,
      collectionHandle: PropTypes.string,
      productHandle: PropTypes.string,
      linkLabel: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          mobileImages: PropTypes.arrayOf(
            PropTypes.shape({
              image: PropTypes.object,
            })
          ),
          desktopImages: PropTypes.arrayOf(
            PropTypes.shape({
              image: PropTypes.object,
            })
          ),
        })
      ),
    })
  ),
  style: PropTypes.string,
  noPageLink: PropTypes.bool,
  className: PropTypes.string,
}
TextImage.defaultProps = {
  data: [],
  style: '',
  noPageLink: false,
  className: '',
}

export default TextImage
