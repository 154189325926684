import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import SliderText from 'components/SliderText'
import TextImage from 'components/TextImage'

import s from './about.module.css'

const About = ({ data }) => {
  const {
    seo: { title: seoTitle, description: seoDescription },
    acfAbout: {
      aboutHeader: { gallery, description },
      aboutTextImage,
    },
  } = data.wordpressPage
  return (
    <Layout>
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={seoTitle} description={seoDescription} />
      <div className={s.wrapper}>
        <div className={s.container}>
          <SliderText text={gallery} />
          {description && (
            <div
              className={s.description}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
          <TextImage className={s.textImage} style="b" data={aboutTextImage} />
        </div>
      </div>
    </Layout>
  )
}

About.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      acfAbout: PropTypes.shape({
        aboutHeader: PropTypes.shape({
          gallery: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
            })
          ),
          description: PropTypes.string,
        }),
        aboutTextImage: PropTypes.array,
      }),
    }),
  }),
}

export default About

export const pageQuery = graphql`
  query PageTemplateByIDorUri($uri: String = "/about/") {
    wordpressPage(uri: { eq: $uri }) {
      seo {
        title
        description
      }
      acfAbout {
        aboutHeader {
          gallery {
            title
          }
          description
        }
        aboutTextImage {
          title
          description
          linkType
          link {
            target
            title
            url
          }
          collectionHandle
          productHandle
          linkLabel
          images {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1200, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  }
`
