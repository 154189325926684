import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Slider from 'components/Slider'

import s from './styles.module.css'

const SliderText = ({ text, className }) => {
  if (text?.length < 1) return null
  const settings = {
    dotsClass: `slick-dots ${s.dots}`,
  }
  return (
    <div className={classNames(s.container, className)}>
      <Slider className={s.slider} {...settings}>
        {text.map(({ title }) => (
          <h2
            key={title}
            className={s.text}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        ))}
      </Slider>
    </div>
  )
}

SliderText.propTypes = {
  text: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  className: PropTypes.string,
}
SliderText.defaultProps = {
  text: [],
  className: '',
}

export default SliderText
